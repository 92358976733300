import React from "react";
import Layout from "../../layouts/layout";
import * as styles from "./confirmation.module.css";

import { HeaderTitle } from "../../components/HeaderTitle";

export default function Index() {
  const title = "Live Webinars on User Testing";

  return (
    <Layout
      title={title}
      metaDescription={
        "We know it can be difficult to find the testing site that’s best for your business. That’s why we’re hosting a live webinar to show you how Userbrain can help you improve your site."
      }
    >
      <article className={styles.headerTitleContainer}>
        <HeaderTitle
          overline={"Thank you"}
          subtitle={"We’re looking forward to seeing you at the Webinar."}
          className={styles.thankYouHeaderTitle}
        >
          You’re Registered!
        </HeaderTitle>
      </article>
    </Layout>
  );
}
